<template>
  <main-layout>
    <main class="section" id="dragonsden">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="iframe_wrap">
              <iframe class="iframe" src="https://www.youtube.com/embed/_OP4QX4RXqY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="column is-6 has-text-centered-mobile hero-title">
            <h1 class="title is-2 is-size-4-mobile has-text-weight-black">
              <span v-t="`dragonsden.see-it`"></span>
            </h1>
            <p v-only-extension-fit class="has-text-grey is-size-4" v-t="`dragonsden.extension-description`"></p>

            <div v-only-extension-fit>
              <Rating></Rating>

              <i18n path="home.rating.label" tag="span">
                <template #value>
                  <strong v-t="`dragonsden.rating.value`"></strong>
                </template>

                <template #max>
                  <strong>5</strong>
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </div>
    </main>
  </main-layout>
</template>

<script>
import Rating from '@/views/components/Rating'

export default {
  components: { Rating }
}
</script>

<style scoped></style>
